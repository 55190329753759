import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Text,
} from '@chakra-ui/core'
import styled from '@emotion/styled'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useLazyQuery, useMutation } from 'react-apollo'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import {
  OrderFormTypeEnum,
  TeamMemberProfessionEnum,
} from '../../../__generated__/globalTypes'
import { useUser } from '../../../context/user-context'
import { useDisplayReferringPhysician } from '../../../hooks'
import i18n from '../../../i18n'
import {
  UpdatePortalUser,
  UpdatePortalUserVariables,
} from '../../../schema/mutations/__generated__/UpdatePortalUser'
import { UPDATE_PORTAL_USER_MUTATION } from '../../../schema/mutations/PortalUserMutations'
import {
  NPISearch,
  NPISearchVariables,
  NPISearch_npiSearch_results,
} from '../../../schema/queries/__generated__/NPISearch'
import { NPI_SEARCH_QUERY } from '../../../schema/queries/NPISearchQueries'
import { LARGE, X_LARGE, mqNames, themeUtils } from '../../../themeUtils'
import { Notification } from '../../../utils'
import { isUSCustomer } from '../../lib'
import {
  Button,
  Heading,
  Input,
  ModalContent,
  ModalOverlay,
  SingleSelect,
} from '../../Shared'
import { NPIFieldWidget } from '../NPI'
import { getProfessionOptions } from './UpdateDetailsModal'

const ModalContentContainer = styled(ModalContent)`
  max-width: 30.5rem;

  ${mqNames(LARGE)} {
    max-width: 38.5rem;
  }

  ${mqNames(X_LARGE)} {
    max-width: 48.5rem;
  }
`

export enum PortalUserSourceEnum {
  CONFIRMING_DETAILS_POPUP = 'CONFIRMING_DETAILS_POPUP',
}

export const getDefaultOrderFormOptions = () => [
  {
    value: OrderFormTypeEnum.VIAL_SELECTOR,
    label: i18n.t('orderFormType.vialList', { ns: ['myAccount'] }),
  },
  {
    value: OrderFormTypeEnum.ACTIVITY_AT_TREATMENT_FOCUSED,
    label: i18n.t('orderFormType.treatmentActivity', { ns: ['myAccount'] }),
  },
]

interface Props {
  isOpen: boolean
  onClose: () => void
  headingTitle?: string
  submitBtnLabel?: string
  source?: string
  onSuccess?: () => void
}

interface FormData {
  firstName: string
  lastName: string
  profession: TeamMemberProfessionEnum
  otherProfession: string
  email: string
  npi: string
  defaultOrderForm: OrderFormTypeEnum
}

const npiPattern = /^\d{10}$/

const validationSchema = () =>
  yup.object().shape({
    firstName: yup
      .string()
      .required(
        i18n.t('basicDetails.firstName.requiredError', { ns: ['myAccount'] })
      ),
    lastName: yup
      .string()
      .required(
        i18n.t('basicDetails.lastName.requiredError', { ns: ['myAccount'] })
      ),
    email: yup
      .string()
      .email(
        i18n.t('basicDetails.email.validationError', { ns: ['myAccount'] })
      ),
    profession: yup
      .string()
      .required(
        i18n.t('basicDetails.profession.requiredError', { ns: ['myAccount'] })
      ),
    otherProfession: yup.string(),
    npi: yup.string().matches(npiPattern, {
      message: i18n.t('npiNumberField.validationError', {
        ns: ['npiWidget'],
      }),
      excludeEmptyString: true,
    }),
    defaultOrderForm: yup.string().required(
      i18n.t('basicDetails.defaultOrderForm.requiredError', {
        ns: ['myAccount'],
      })
    ),
  })

const { green, blue } = themeUtils.colors

export const PortalUserForm: React.FC<Props> = ({
  isOpen,
  onClose,
  headingTitle = i18n.t('basicDetails.deafultHeading', {
    ns: ['myAccount'],
  }),
  submitBtnLabel = i18n.t('basicDetails.defaultSubmitBtnLabel', {
    ns: ['myAccount'],
  }),
  source,
  onSuccess,
}) => {
  const { t } = useTranslation(['myAccount', 'npiWidget'])
  const submitButtonRef = useRef<HTMLButtonElement>(null)

  const user = useUser()
  const [isNPISearchSection, setNPISearchSection] = useState<boolean>(false)
  const DEFAULT_ORDER_FORM_OPTIONS = getDefaultOrderFormOptions()

  const displayReferringPhysician = useDisplayReferringPhysician()

  const [
    updatePortalUser,
    { loading: loadingUpdate, error: errorUpdate },
  ] = useMutation<UpdatePortalUser, UpdatePortalUserVariables>(
    UPDATE_PORTAL_USER_MUTATION
  )

  useEffect(() => {
    if (errorUpdate) Notification.error(errorUpdate.message)
  }, [errorUpdate])

  const [
    searchNPI,
    { data: searchNPIDATA, loading: searchNPILoading },
  ] = useLazyQuery<NPISearch, NPISearchVariables>(NPI_SEARCH_QUERY)

  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    clearError,
    setError,
    reset,
    triggerValidation,
  } = useForm<FormData>({
    validationSchema: validationSchema(),
    mode: 'onChange',
    defaultValues: {
      firstName: (user && user.firstName) || '',
      lastName: (user && user.lastName) || '',
      email: (user && user.email) || '',
      profession:
        (source === PortalUserSourceEnum.CONFIRMING_DETAILS_POPUP &&
          TeamMemberProfessionEnum.treating_physician) ||
        (user && user.profession) ||
        undefined,
      otherProfession:
        (source === PortalUserSourceEnum.CONFIRMING_DETAILS_POPUP && '') ||
        (user && user.profession === TeamMemberProfessionEnum.other
          ? user.otherProfession
          : '') ||
        '',
      npi:
        (user && user.profession === TeamMemberProfessionEnum.treating_physician
          ? user.npi
          : '') || '',
      defaultOrderForm: user?.defaultOrderForm,
    },
  })

  useEffect(() => {
    register({ name: 'profession' })
    register({ name: 'npi' })
    register({ name: 'defaultOrderForm' })
  }, [register])

  const profession = watch('profession') as TeamMemberProfessionEnum
  const otherProfession = watch('otherProfession') as string
  const npi = watch('npi') as string
  const firstName = watch('firstName') as string
  const lastName = watch('lastName') as string
  const defaultOrderForm = watch('defaultOrderForm') as OrderFormTypeEnum

  const debounceTimeout = useRef<NodeJS.Timeout>()

  useEffect(() => {
    callingNPISearchWithNPI()
  }, [npi])

  useEffect(() => {
    if (isNPISearchSection) {
      callingNPISearchWithNames()
    }
  }, [firstName, lastName])

  const callingNPISearchWithNames = (useDebounce = true): void => {
    if (profession !== TeamMemberProfessionEnum.treating_physician) return

    if (useDebounce) {
      // Clear any existing debounce timeout
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current)
      }
      // Set a new debounce timeout
      debounceTimeout.current = setTimeout(() => {
        // Make your API request here
        searchNPI({
          variables: {
            firstName: firstName,
            lastName: lastName,
          },
        })
      }, 500) // Adjust the delay (in milliseconds) as needed
    } else {
      searchNPI({
        variables: {
          firstName: firstName,
          lastName: lastName,
        },
      })
    }
  }

  const callingNPISearchWithNPI = (useDebounce = true): void => {
    if (profession !== TeamMemberProfessionEnum.treating_physician) return
    const isNpiValid = npiPattern.test(npi)
    if (npi && isNpiValid) {
      if (useDebounce) {
        // Clear any existing debounce timeout
        if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current)
        }
        // Set a new debounce timeout
        debounceTimeout.current = setTimeout(() => {
          // Make your API request here
          searchNPI({
            variables: {
              number: npi,
              taxonomyDescription: '',
            },
          })
        }, 500) // Adjust the delay (in milliseconds) as needed
      } else {
        searchNPI({
          variables: {
            number: npi,
            taxonomyDescription: '',
          },
        })
      }
    } else if (npi && !isNpiValid) {
      triggerValidation('npi')
    }
  }

  const onChangeNPI = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue('npi', event.target.value)
    clearError('npi')
  }

  const handleSubmitRef = (): void => {
    submitButtonRef.current?.click()
  }

  const onProfessionChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value as TeamMemberProfessionEnum
    setValue('profession', value)
    clearError('profession')

    // clear value of npi if value is not Treating Physician
    if (value !== TeamMemberProfessionEnum.treating_physician) {
      setValue('npi', '')
      clearError('npi')
    }

    // clear value of other Profession if value is not others
    if (value !== TeamMemberProfessionEnum.other) {
      setValue('otherProfession', '')
      clearError('otherProfession')
    }
  }

  const onDefaultFormChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value as OrderFormTypeEnum
    setValue('defaultOrderForm', value)
    clearError('defaultOrderForm')
  }

  const onSubmit = async (results: FormData): Promise<void> => {
    if (!user) return
    const { data, errors } = await updatePortalUser({
      variables: {
        input: {
          id: user.id,
          teamMemberAttributes: {
            id: user.teamMember.id,
            profession: results.profession,
            otherProfession: results.otherProfession ?? null,
            npi: results.npi ?? null,
          },
          hasUpdatedDetails:
            source === PortalUserSourceEnum.CONFIRMING_DETAILS_POPUP
              ? true
              : undefined,
          defaultOrderForm: results.defaultOrderForm,
        },
      },
      refetchQueries:
        source === PortalUserSourceEnum.CONFIRMING_DETAILS_POPUP
          ? undefined
          : ['GetMyPortalUser'],
    })
    if (errors) {
      errors.forEach(error => Notification.error(error.message))
    } else {
      if (data && data.updatePortalUser) {
        if (data.updatePortalUser.__typename === 'Error') {
          data.updatePortalUser.errors.forEach(error => {
            if (error.path) {
              setError(error.path[0], error.type, error.message)
            }
          })
          Notification.error(t('toast.failure', { ns: ['myAccount'] }), {
            timeout: 3000,
          })
        } else {
          Notification.success(t('toast.success', { ns: ['myAccount'] }))
          onSuccessClose()
        }
      }
    }
  }

  const onSuccessClose = (): void => {
    if (onSuccess) {
      onSuccess()
    } else {
      onClose()
    }
    reset()
    setNPISearchSection(false)
  }

  const onModalClose = (): void => {
    reset()
    onClose()
    setNPISearchSection(false)
  }

  const onSelectNPI = (result: NPISearch_npiSearch_results): void => {
    const npiValue = result.number ?? ''
    setValue('npi', npiValue)
    clearError('npi')
    onToggleNPISection()
  }

  const onToggleNPISection = (): void => {
    if (!isNPISearchSection === true) {
      triggerValidation()
      if (!firstName || !lastName) {
        return
      }
      callingNPISearchWithNames(false)
    } else {
      setValue('firstName', user?.firstName)
      clearError('firstName')
      setValue('lastName', user?.lastName)
      clearError('lastName')
    }
    setNPISearchSection(!isNPISearchSection)
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onModalClose}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContentContainer>
          <ModalHeader padding="0">
            <Heading color={blue} as="h2" marginBottom="0">
              {headingTitle}
            </Heading>
          </ModalHeader>
          <ModalCloseButton _focus={undefined} />

          <ModalBody borderTop="1px solid #EFEFEF" padding="2rem 0">
            <Box>
              <form
                id="PortalUserForm"
                autoComplete="off"
                acceptCharset="UTF-8"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                {isNPISearchSection && (
                  <Text
                    color={themeUtils.colors.blue}
                    fontSize={'xl'}
                    fontWeight={'bold'}
                    margin={'0 0 1rem 0'}
                  >
                    <span
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      onClick={onToggleNPISection}
                    >
                      {t('closeSearchLabel', {
                        ns: ['npiWidget'],
                      })}
                    </span>
                  </Text>
                )}
                <Input
                  label={t('basicDetails.firstName.label', {
                    ns: ['myAccount'],
                  })}
                  type="text"
                  id="firstName"
                  name="firstName"
                  innerRef={register}
                  invalid={!!errors.firstName}
                  error={errors.firstName && errors.firstName.message}
                  data-cy="user-firstname-input"
                  isRequired
                  disabled={isNPISearchSection ? false : true}
                />
                <Input
                  label={t('basicDetails.lastName.label', {
                    ns: ['myAccount'],
                  })}
                  type="text"
                  id="lastName"
                  name="lastName"
                  innerRef={register}
                  invalid={!!errors.lastName}
                  error={errors.lastName && errors.lastName.message}
                  data-cy="user-lastName-input"
                  isRequired
                  disabled={isNPISearchSection ? false : true}
                />

                <Input
                  label={t('basicDetails.email.formLabel', {
                    ns: ['myAccount'],
                  })}
                  type="email"
                  id="email"
                  name="email"
                  innerRef={register}
                  invalid={!!errors.email}
                  error={errors.email && errors.email.message}
                  data-cy="user-email-input"
                  disabled={true}
                  hidden={isNPISearchSection}
                />
                <SingleSelect
                  label={t('basicDetails.profession.label', {
                    ns: ['myAccount'],
                  })}
                  name="Profession"
                  value={profession}
                  onChange={onProfessionChange}
                  options={getProfessionOptions(displayReferringPhysician)}
                  error={errors.profession && errors.profession.message}
                  data-cy="user-profession-select"
                  isRequired
                  hidden={isNPISearchSection}
                />
                {profession === TeamMemberProfessionEnum.other && (
                  <Input
                    label={t('basicDetails.otherProfession.label', {
                      ns: ['myAccount'],
                    })}
                    type="text"
                    id="otherProfession"
                    name="otherProfession"
                    innerRef={register}
                    value={otherProfession}
                    invalid={!!errors.otherProfession}
                    error={
                      errors.otherProfession && errors.otherProfession.message
                    }
                    data-cy="user-otherProfession-input"
                  />
                )}
                {profession === TeamMemberProfessionEnum.treating_physician &&
                  isUSCustomer(user) && (
                    <>
                      {!isNPISearchSection && (
                        <Input
                          label={t('npiNumberField.formLabel', {
                            ns: ['npiWidget'],
                          })}
                          type="text"
                          id="npi"
                          name="npi"
                          onChange={onChangeNPI}
                          // innerRef={register}
                          value={npi}
                          invalid={!!errors.npi}
                          error={errors.npi && errors.npi.message}
                          data-cy="user-npi-input"
                        />
                      )}

                      <NPIFieldWidget
                        profession={profession}
                        isNPISearchMode={isNPISearchSection}
                        onNPISearchLinkClick={onToggleNPISection}
                        npi={npi}
                        data={
                          !firstName || !lastName ? undefined : searchNPIDATA
                        }
                        loading={searchNPILoading}
                        onSelectNPI={onSelectNPI}
                        linkLabel={t('npiNumberField.searchLinkLabel', {
                          ns: ['npiWidget'],
                        })}
                      />
                    </>
                  )}
                <SingleSelect
                  label={t('basicDetails.defaultOrderForm.label', {
                    ns: ['myAccount'],
                  })}
                  name="defaultOrderForm"
                  value={defaultOrderForm}
                  onChange={onDefaultFormChange}
                  options={
                    !isUSCustomer(user)
                      ? DEFAULT_ORDER_FORM_OPTIONS.slice().reverse()
                      : DEFAULT_ORDER_FORM_OPTIONS
                  }
                  error={
                    errors.defaultOrderForm && errors.defaultOrderForm.message
                  }
                  data-cy="user-default-order-form-select"
                  isRequired
                  hidden={isNPISearchSection}
                />
                <button hidden={true} type="submit" ref={submitButtonRef} />
              </form>
            </Box>
          </ModalBody>
          <ModalFooter borderTop="1px solid #EFEFEF" paddingTop="2rem">
            <Button
              backgroundColor={green}
              isFullWidth
              onClick={handleSubmitRef}
              isLoading={loadingUpdate}
              isDisabled={isNPISearchSection || searchNPILoading}
            >
              {submitBtnLabel}
            </Button>
          </ModalFooter>
        </ModalContentContainer>
      </Modal>
    </>
  )
}
