import gql from 'graphql-tag'

export const GET_CUSTOMER_QUERY = gql`
  query GetCustomer {
    customer {
      mnxEligibleCustomer
      regionCode
      treatingPhysicians {
        id
        name
        portalUserId
      }
      referringPhysicians {
        id
        name
        portalUserId
      }
    }
  }
`
export const CUSTOMERS_QUERY = gql`
  query Customers {
    customers {
      id
      name
    }
  }
`
