import {
  Box,
  BoxProps,
  Flex,
  Grid,
  GridProps,
  PseudoBox,
} from '@chakra-ui/core'
import styled from '@emotion/styled'
import { format, parse } from 'date-fns'
import { lighten } from 'polished'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DAY_OF_WEEK_FORMAT } from '../../../constants'
import { useAuth } from '../../../context/auth-context'
import { useFeatureFlag } from '../../../context/FeatureFlagsContext'
import { useUser } from '../../../context/user-context'
import { useDisplayReferringPhysician } from '../../../hooks'
import { getTimeFormat } from '../../../i18n'
import { OrderShow_order } from '../../../schema/queries/__generated__/OrderShow'
import { mqNames, SMALL, themeUtils } from '../../../themeUtils'
import { OrderViewEditEnum } from '../../../types'
import { formatDate } from '../../../utils'
import { PencilIcon } from '../../Icons'
import { isROWCustomer } from '../../lib'
import { DetailText } from '../../Shared'
import { getTreatmentIntentOptions } from '../NewOrderForm'
import { OrderShowShippingWidgetForm } from './OrderShowShippingWidgetForm'

interface Props {
  order: OrderShow_order
  refetch: Function
}

const ShippingWrapper = styled(Box)<BoxProps>`
  max-width: 100%;

  p {
    font-size: 1.5rem;
  }
`
const OrderDetailsGrid = styled(Grid)<GridProps>`
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  ${mqNames(SMALL)} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
`

const { midnightBlue } = themeUtils.colors

export const OrderShowShippingWidget: React.FC<Props> = ({
  order,
  refetch,
}) => {
  const { t } = useTranslation(['orderShowScreen'])
  const user = useUser()
  const auth = useAuth()
  const displayReferringPhysician = useDisplayReferringPhysician()
  const canEditOrders = auth?.user?.canEditOrders
  const treatingPhysicianFeatureFlag = useFeatureFlag('use_treating_physician')
  const [editArray, setEditArray] = useState<OrderViewEditEnum[]>([])
  if (!order) return null
  const treatmentTime = parse(order.treatmentTime, 'HH:mm', new Date())
  const IsAccessoryOnlyOrder = !order.treatments.length

  const orderPatientIntentValue = getTreatmentIntentOptions().find(
    o => o.value == order.treatmentIntent
  )?.label

  const onEdit = (val: OrderViewEditEnum): void => {
    const arrayClone = editArray.slice()
    arrayClone?.push(val)
    setEditArray(arrayClone)
  }
  const onCloseEditor = (val: OrderViewEditEnum): void => {
    const arrayClone = editArray.filter(v => v !== val)
    setEditArray(arrayClone)
  }
  return (
    <ShippingWrapper>
      <OrderDetailsGrid>
        <DetailText
          title={t('showShippingWidget.orderNo', { ns: ['orderShowScreen'] })}
        >
          {order.orderNumber}
        </DetailText>
        <DetailText
          title={t('showShippingWidget.patientRef', {
            ns: ['orderShowScreen'],
          })}
        >
          {order?.patientRef ||
            t('showShippingWidget.tbd', { ns: ['orderShowScreen'] })}
        </DetailText>
        <DetailText
          title={t('showShippingWidget.treatmentDate', {
            ns: ['orderShowScreen'],
          })}
        >
          {order?.treatmentDate &&
            formatDate(order.treatmentDate, DAY_OF_WEEK_FORMAT)}
        </DetailText>
        <DetailText
          title={t('showShippingWidget.treatmentTime', {
            ns: ['orderShowScreen'],
          })}
        >
          {format(treatmentTime, getTimeFormat())}
        </DetailText>

        {isROWCustomer(user) &&
          !IsAccessoryOnlyOrder &&
          !editArray.includes(OrderViewEditEnum.TREATMENT_INTENT) && (
            <DetailText
              title={t('showShippingWidget.patientIntent', {
                ns: ['orderShowScreen'],
              })}
              width={'100%'}
            >
              <Flex
                width="100%"
                marginTop={{ base: '1rem', sm: 0 }}
                marginBottom="1rem"
              >
                <Box
                  as="span"
                  cursor={canEditOrders ? 'pointer' : ''}
                  onClick={
                    canEditOrders
                      ? () => onEdit(OrderViewEditEnum.TREATMENT_INTENT)
                      : () => {}
                  }
                >
                  {orderPatientIntentValue ||
                    t('patientIntent.awaiting', { ns: ['orderShowScreen'] })}
                </Box>
                {canEditOrders && (
                  <PseudoBox
                    cursor="pointer"
                    color={midnightBlue}
                    _hover={{
                      color: lighten(0.2, midnightBlue),
                    }}
                    onClick={() => onEdit(OrderViewEditEnum.TREATMENT_INTENT)}
                  >
                    <PencilIcon
                      title={t('patientIntent.edit', {
                        ns: ['orderShowScreen'],
                      })}
                    />
                  </PseudoBox>
                )}
              </Flex>
            </DetailText>
          )}

        {treatingPhysicianFeatureFlag &&
          !editArray.includes(OrderViewEditEnum.TREATING_PHYSICIAN) && (
            <DetailText
              title={t('showShippingWidget.treatingPhys', {
                ns: ['orderShowScreen'],
              })}
              width={'100%'}
            >
              <Flex
                width="100%"
                marginTop={{ base: '1rem', sm: 0 }}
                marginBottom="1rem"
              >
                <Box
                  as="span"
                  cursor={canEditOrders ? 'pointer' : ''}
                  onClick={
                    canEditOrders
                      ? () => onEdit(OrderViewEditEnum.TREATING_PHYSICIAN)
                      : () => {}
                  }
                >
                  {order.treatingPhysicianName ||
                    t('treatingPhysForm.awaiting', { ns: ['orderShowScreen'] })}
                </Box>
                {canEditOrders && (
                  <PseudoBox
                    cursor="pointer"
                    color={midnightBlue}
                    _hover={{
                      color: lighten(0.2, midnightBlue),
                    }}
                    onClick={() => onEdit(OrderViewEditEnum.TREATING_PHYSICIAN)}
                  >
                    <PencilIcon
                      title={t('treatingPhysForm.edit', {
                        ns: ['orderShowScreen'],
                      })}
                    />
                  </PseudoBox>
                )}
              </Flex>
            </DetailText>
          )}

        {displayReferringPhysician &&
          !editArray.includes(OrderViewEditEnum.REFERRING_PHYSICIAN) && (
            <DetailText
              title={t('showShippingWidget.referringPhys', {
                ns: ['orderShowScreen'],
              })}
              width={'100%'}
            >
              <Flex
                width="100%"
                marginTop={{ base: '1rem', sm: 0 }}
                marginBottom="1rem"
              >
                <Box
                  as="span"
                  cursor={canEditOrders ? 'pointer' : ''}
                  onClick={
                    canEditOrders
                      ? () => onEdit(OrderViewEditEnum.REFERRING_PHYSICIAN)
                      : () => {}
                  }
                >
                  {order.referringPhysicianName ||
                    t('referringPhysForm.awaiting', {
                      ns: ['orderShowScreen'],
                    })}
                </Box>
                {canEditOrders && (
                  <PseudoBox
                    cursor="pointer"
                    color={midnightBlue}
                    _hover={{
                      color: lighten(0.2, midnightBlue),
                    }}
                    onClick={() =>
                      onEdit(OrderViewEditEnum.REFERRING_PHYSICIAN)
                    }
                  >
                    <PencilIcon
                      title={t('referringPhysForm.edit', {
                        ns: ['orderShowScreen'],
                      })}
                    />
                  </PseudoBox>
                )}
              </Flex>
            </DetailText>
          )}
      </OrderDetailsGrid>
      {editArray.length > 0 && (
        <Flex flexDirection="column" gridGap={2} marginBottom="1rem">
          {editArray.map(edit => (
            <OrderShowShippingWidgetForm
              order={order}
              canEditOrders={canEditOrders ?? false}
              refetch={refetch}
              formType={edit}
              key={edit}
              onCloseEditor={onCloseEditor}
            />
          ))}
        </Flex>
      )}
    </ShippingWrapper>
  )
}
