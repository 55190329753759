import gql from 'graphql-tag'

export const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrder($input: [CreateOrderInput!]!) {
    createOrder(input: $input) {
      __typename
      ... on Order {
        id
        orderNumber
        status
        sourceType
        cancellationReason {
          __typename
          id
          code
          customerFacingDescription
          type
        }
      }
      ... on Error {
        errors {
          path
          message
          type
        }
      }
    }
  }
`

export const CANCEL_ORDER_MUTATION = gql`
  mutation CancelOrder($input: CancelOrderInput!) {
    cancelOrder(input: $input) {
      __typename
      ... on Order {
        id
        orderNumber
        status
        cancellationReason {
          __typename
          id
          code
          customerFacingDescription
          type
        }
      }
      ... on Error {
        errors {
          path
          message
          type
        }
      }
    }
  }
`

export const UPDATE_PO_NUMBER = gql`
  mutation UpdatePoNumber($input: UpdatePoNumberInput!) {
    updatePoNumber(input: $input) {
      __typename
      ... on Order {
        id
        poNumber
      }
      ... on Error {
        errors {
          path
          message
          type
        }
      }
    }
  }
`

export const UPDATE_ORDER_MUTATION = gql`
  mutation UpdateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      __typename
      ... on Order {
        id
        poNumber
        patientRef
        treatingPhysicianName
        treatingPhysicianId
      }
      ... on Error {
        errors {
          path
          message
          type
        }
      }
    }
  }
`

export const UPDATE_TREATING_PHYSICIAN = gql`
  mutation UpdateTreatingPhysician($input: UpdateTreatingPhysicianInput!) {
    updateTreatingPhysician(input: $input) {
      __typename
      ... on Order {
        id
        treatingPhysicianId
      }
      ... on Error {
        errors {
          path
          message
          type
        }
      }
    }
  }
`

export const UPDATE_REFERRING_PHYSICIAN = gql`
  mutation UpdateReferringPhysician($input: UpdateReferringPhysicianInput!) {
    updateReferringPhysician(input: $input) {
      __typename
      ... on Order {
        id
        referringPhysicianId
        referringPhysicianName
      }
      ... on Error {
        errors {
          path
          message
          type
        }
      }
    }
  }
`

export const UPDATE_TREATMENT_INTENT = gql`
  mutation UpdateTreatmentIntent($input: UpdateTreatmentIntentInput!) {
    updateTreatmentIntent(input: $input) {
      __typename
      ... on Order {
        id
        treatmentIntent
      }
      ... on Error {
        errors {
          path
          message
          type
        }
      }
    }
  }
`
