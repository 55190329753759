import { useMutation } from '@apollo/react-hooks'
import { Flex, FlexProps, useDisclosure } from '@chakra-ui/core'
import { Icons } from '@chakra-ui/core/dist/theme/icons'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { useUser } from '../../../context/user-context'
import { UPDATE_REFERRING_PHYSICIAN } from '../../../schema/mutations'
import { CreatePortalTeamMember_createPortalTeamMember_TeamMember } from '../../../schema/mutations/__generated__/CreatePortalTeamMember'
import {
  UpdateReferringPhysician,
  UpdateReferringPhysicianVariables,
} from '../../../schema/mutations/__generated__/UpdateReferringPhysician'
import { GetCustomer } from '../../../schema/queries/__generated__/GetCustomer'
import { OrderShow_order } from '../../../schema/queries/__generated__/OrderShow'
import { GET_CUSTOMER_QUERY } from '../../../schema/queries/CustomerQueries'
import { themeUtils } from '../../../themeUtils'
import { ThemeVersion } from '../../../types'
import { handleErrors, Notification } from '../../../utils'
import { isROWCustomer } from '../../lib'
import {
  TeamMemberForm,
  TeamMemberSoucreEnum,
} from '../../MyAccount/TeamMember'
import {
  Button,
  CustomSelect,
  DetailText,
  InlineErrorMessage,
  Option,
} from '../../Shared'

interface Props {
  order: OrderShow_order
  refetch: Function
  canEdit: boolean | undefined
  wrapperProps: FlexProps
  onCloseEditor: Function
}

const buttonStyles = {
  marginLeft: '1rem',
  height: 'auto',
  width: 'auto',
  minWidth: '6rem',
}

export const ReferringPhysicianForm: React.FC<Props> = ({
  order,
  refetch,
  canEdit = false,
  wrapperProps,
  onCloseEditor,
}) => {
  const { t } = useTranslation(['orderShowScreen'])
  const user = useUser()
  const [referringPhysicianId, setReferringPhysicianId] = useState(
    order.referringPhysicianId
  )
  const [referringPhysicianName, setReferringPhysicianName] = useState(
    order.referringPhysicianName
  )
  const [editMode, setEditMode] = useState(true)
  const [updateReferringPhysician] = useMutation<
    UpdateReferringPhysician,
    UpdateReferringPhysicianVariables
  >(UPDATE_REFERRING_PHYSICIAN)

  const [dimension, updateDimension] = useState({ width: window.innerWidth })
  const [referringPhyisicianLabel, setReferringPhyisicianLabel] = useState(
    dimension.width < 720
      ? `${t('referringPhysForm.referringPhys', { ns: ['orderShowScreen'] })}`
      : `${t('referringPhysForm.pleaseSelect', { ns: ['orderShowScreen'] })}`
  )

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data: customerData } = useQuery<GetCustomer>(GET_CUSTOMER_QUERY)

  const resetField = () => {
    setEditMode(false)
    setReferringPhysicianId(order.referringPhysicianId || '')
    setReferringPhysicianName(order.referringPhysicianName || '')
    onCloseEditor()
  }

  const onUpdateReferringPhysician = async (
    referringPhysicianId: string,
    name: string
  ): Promise<void> => {
    if (!editMode) return

    const { data, errors } = await updateReferringPhysician({
      variables: {
        input: {
          id: order?.id,
          referringPhysicianId: referringPhysicianId,
          referringPhysicianName: name,
        },
      },
    })
    setEditMode(false)

    const successMessage = t('referringPhysForm.updated', {
      ns: ['orderShowScreen'],
    })
    refetch()

    if (errors) {
      errors.forEach(error => Notification.error(error.message))
    } else {
      if (data && data.updateReferringPhysician.__typename === 'Error') {
        handleErrors(data.updateReferringPhysician.errors)
      } else {
        refetch()
        Notification.success(successMessage)
      }
    }
    onCloseEditor()
  }

  const onReferringPhysicianChange = (option: Option | null): void => {
    if (option?.value === 'add_member') {
      // Open add team member modal
      onOpen()
    } else {
      setReferringPhysicianId(option?.value)
      setReferringPhysicianName(option?.label || '')
    }
  }

  const handleTPModalClose = (
    createdUser?: CreatePortalTeamMember_createPortalTeamMember_TeamMember
  ): void => {
    onClose()
    if (createdUser) {
      setReferringPhysicianId(createdUser.id)
      setReferringPhysicianName(createdUser.name || '')
    }
  }

  const referringPhysicianOptions = useMemo(() => {
    const options: Option[] = []
    if (customerData?.customer.referringPhysicians) {
      customerData?.customer.referringPhysicians.map(t => {
        return options.push({
          value: t.id,
          label: t.name,
        })
      })
    }
    options.push({
      value: 'add_member',
      label: t('referringPhysForm.add', { ns: ['orderShowScreen'] }),
      isLink: true,
    })
    return options
  }, [customerData?.customer.referringPhysicians])

  const referringPhysicianOptionVal = useMemo(() => {
    const value: Option | undefined = referringPhysicianOptions.find(
      o => o.value === referringPhysicianId
    )
    return value ?? null
  }, [
    referringPhysicianId,
    referringPhysicianName,
    customerData?.customer.referringPhysicians,
  ])

  useEffect(() => {
    window.addEventListener('resize', () => {
      updateDimension({
        ...dimension,
        width: window.innerWidth,
      })
      const label =
        window.innerWidth < 720
          ? t('referringPhysForm.referringPhys', { ns: ['orderShowScreen'] })
          : t('referringPhysForm.pleaseSelect', { ns: ['orderShowScreen'] })
      setReferringPhyisicianLabel(label)
    })
  }, [window.innerWidth, window])

  return (
    <>
      <DetailText
        title={t('showShippingWidget.referringPhys', {
          ns: ['orderShowScreen'],
        })}
        width={'100%'}
      >
        <Flex {...wrapperProps}>
          <>
            <CustomSelect
              placeholder={referringPhyisicianLabel}
              value={referringPhysicianOptionVal}
              onChange={option => onReferringPhysicianChange(option as Option)}
              options={referringPhysicianOptions}
              isSearchable={false}
              isClearable={true}
              data-cy="order-referring-physician-select"
              version={ThemeVersion.V2}
            />
            <Flex>
              <Button
                {...buttonStyles}
                maxHeight="60px"
                onClick={() =>
                  onUpdateReferringPhysician(
                    referringPhysicianId || '',
                    referringPhysicianName || ''
                  )
                }
              >
                {t('referringPhysForm.save', { ns: ['orderShowScreen'] })}
              </Button>

              <Button
                {...buttonStyles}
                backgroundColor={themeUtils.colors.red}
                maxHeight="60px"
                onClick={() => resetField()}
              >
                {t('referringPhysForm.cancel', { ns: ['orderShowScreen'] })}
              </Button>
            </Flex>
          </>
        </Flex>
      </DetailText>
      {isROWCustomer(user) &&
        customerData?.customer?.referringPhysicians?.find(
          o => o.id === referringPhysicianId
        )?.portalUserId === null && (
          <InlineErrorMessage version={ThemeVersion.V2} icon={'info' as Icons}>
            {t('referringPhysForm.message', { ns: ['orderShowScreen'] })}
          </InlineErrorMessage>
        )}
      <TeamMemberForm
        isOpen={isOpen}
        onClose={handleTPModalClose}
        source={TeamMemberSoucreEnum.ADD_REFERRING_PHYSICIAN}
        headingTitle={t('referringPhysForm.add', { ns: ['orderShowScreen'] })}
        submitBtnLabel={t('referringPhysForm.add', { ns: ['orderShowScreen'] })}
      />
    </>
  )
}
