/*
  Creating this to avoid repeating code endlessly. Wanted to create a helper function,
  but we are using context her so ended up with a custom hook.
*/

import { isCACustomer, isROWCustomer, isUSCustomer } from '../components/lib'
import { useFeatureFlag } from '../context/FeatureFlagsContext'
import { useUser } from '../context/user-context'

export const useDisplayReferringPhysician = (): boolean => {
  const user = useUser()
  const referringPhysicianFeatureUSFlag = useFeatureFlag(
    'use_referring_physician_US'
  )
  const referringPhysicianFeatureROWFlag = useFeatureFlag(
    'use_referring_physician_ROW'
  )

  const isUS = isUSCustomer(user)
  const isRowOrCA = isROWCustomer(user) || isCACustomer(user)
  const displayReferringPhysician =
    (referringPhysicianFeatureUSFlag && isUS) ||
    (referringPhysicianFeatureROWFlag && isRowOrCA)
  return displayReferringPhysician
}
