import React from 'react'
import styled from '@emotion/styled'
import { themeUtils } from '../../../themeUtils'
import { DashboardIcon, OrdersIcon } from '../../Icons'
import { ActiveLink } from '../../Shared'
import { client } from '../../../schema/apollo-client'
import { ORDER_FILTERS_QUERY } from '../../../schema/queries'
import { useMutation } from '@apollo/react-hooks'
import {
  SetOrderFilters,
  SetOrderFiltersVariables,
} from '../../../schema/mutations/__generated__/SetOrderFilters'
import { SET_ORDER_FILTERS_MUTATION } from '../../../schema/mutations'
import { DASHBOARD_PATH, ORDERS_INDEX_PATH } from '../../../routes'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'

const Nav = styled.nav`
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
  }

  li {
    min-width: 10rem;
  }

  @media print {
    li:first-child {
      display: none;
    }
  }

  a {
    font-size: 1.4rem;
    text-decoration: none;
    color: ${themeUtils.colors.slateGrey};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8rem;

    svg {
      padding-right: 1rem;
      fill: ${themeUtils.colors.slateGrey};
    }

    &:hover {
      color: ${themeUtils.colors.white};

      svg {
        fill: ${themeUtils.colors.white};
      }
    }
  }

  a.active {
    color: ${themeUtils.colors.white};
    background: linear-gradient(to bottom, #0075d6 0%, #0075d6 5%),
      linear-gradient(
        180deg,
        rgba(0, 57, 104, 0) 0%,
        rgba(0, 107, 195, 0.59) 100%
      );
    background-position: bottom;
    background-repeat: repeat-x;
    background-size: 0.4rem 0.4rem, 0.4rem 2.7rem;

    & svg {
      fill: ${themeUtils.colors.white};
    }
  }
`

const NavBar: React.FC = () => {
  const { t } = useTranslation(['navBar'])
  const [setFiltersMutation] = useMutation<
    SetOrderFilters,
    SetOrderFiltersVariables
  >(SET_ORDER_FILTERS_MUTATION)

  const onClickOrders: any = async (event: any) => {
    event.preventDefault()

    const updatedDataFromCache = client.readQuery({
      query: ORDER_FILTERS_QUERY,
    })

    const newData = {
      ...updatedDataFromCache.orderFilters,
      status: null,
    }

    await setFiltersMutation({
      variables: {
        input: newData,
      },
    })

    navigate(ORDERS_INDEX_PATH)
  }
  // @ts-ignore
  return (
    <Nav>
      <ul>
        <li style={{ padding: '0 0.5rem' }}>
          <ActiveLink exact to={DASHBOARD_PATH}>
            <DashboardIcon width={26} height={24} />{' '}
            {t('menu.dashboard', { ns: ['navBar'] })}
          </ActiveLink>
        </li>
        <li style={{ padding: '0 0.5rem' }}>
          <ActiveLink to="/orders" onClick={onClickOrders}>
            <OrdersIcon width={24} height={24} />{' '}
            {t('menu.orders', { ns: ['navBar'] })}
          </ActiveLink>
        </li>
      </ul>
    </Nav>
  )
}

export { NavBar }
