import { useMutation } from '@apollo/react-hooks'
import { Flex, FlexProps, useDisclosure } from '@chakra-ui/core'
import { Icons } from '@chakra-ui/core/dist/theme/icons'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { useUser } from '../../../context/user-context'
import { UPDATE_TREATING_PHYSICIAN } from '../../../schema/mutations'
import { CreatePortalTeamMember_createPortalTeamMember_TeamMember } from '../../../schema/mutations/__generated__/CreatePortalTeamMember'
import {
  UpdateTreatingPhysician,
  UpdateTreatingPhysicianVariables,
} from '../../../schema/mutations/__generated__/UpdateTreatingPhysician'
import { GetCustomer } from '../../../schema/queries/__generated__/GetCustomer'
import { OrderShow_order } from '../../../schema/queries/__generated__/OrderShow'
import { GET_CUSTOMER_QUERY } from '../../../schema/queries/CustomerQueries'
import { themeUtils } from '../../../themeUtils'
import { ThemeVersion } from '../../../types'
import { handleErrors, Notification } from '../../../utils'
import { isROWCustomer } from '../../lib'
import {
  TeamMemberForm,
  TeamMemberSoucreEnum,
} from '../../MyAccount/TeamMember'
import {
  Button,
  CustomSelect,
  DetailText,
  InlineErrorMessage,
  Option,
} from '../../Shared'

interface Props {
  order: OrderShow_order
  refetch: Function
  canEdit: boolean | undefined
  wrapperProps: FlexProps
  onCloseEditor: Function
}

const buttonStyles = {
  marginLeft: '1rem',
  height: 'auto',
  width: 'auto',
  minWidth: '6rem',
}

export const TreatingPhysicianForm: React.FC<Props> = ({
  order,
  refetch,
  canEdit = false,
  wrapperProps,
  onCloseEditor,
}) => {
  const { t } = useTranslation(['orderShowScreen'])
  const user = useUser()
  const [treatingPhysicianId, setTreatingPhysicianId] = useState(
    order.treatingPhysicianId
  )
  const [treatingPhysicianName, setTreatingPhysicianName] = useState(
    order.treatingPhysicianName
  )
  const [editMode, setEditMode] = useState(true)
  const [updateTreatingPhysician] = useMutation<
    UpdateTreatingPhysician,
    UpdateTreatingPhysicianVariables
  >(UPDATE_TREATING_PHYSICIAN)

  const [dimension, updateDimension] = useState({ width: window.innerWidth })
  const [treatingPhyisicianLabel, setTreatingPhyisicianLabel] = useState(
    dimension.width < 720
      ? `${t('treatingPhysForm.treatingPhys', { ns: ['orderShowScreen'] })}`
      : `${t('treatingPhysForm.pleaseSelect', { ns: ['orderShowScreen'] })}`
  )

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data: customerData } = useQuery<GetCustomer>(GET_CUSTOMER_QUERY)

  const resetField = () => {
    setEditMode(false)
    setTreatingPhysicianId(order.treatingPhysicianId || '')
    setTreatingPhysicianName(order.treatingPhysicianName || '')
    onCloseEditor()
  }

  const onUpdateTreatingPhysician = async (
    treatingPhysicianId: string,
    name: string
  ): Promise<void> => {
    if (!editMode) return

    const { data, errors } = await updateTreatingPhysician({
      variables: {
        input: {
          id: order?.id,
          treatingPhysicianId: treatingPhysicianId,
          treatingPhysicianName: name,
        },
      },
    })
    setEditMode(false)

    const successMessage = t('treatingPhysForm.updated', {
      ns: ['orderShowScreen'],
    })
    refetch()

    if (errors) {
      errors.forEach(error => Notification.error(error.message))
    } else {
      if (data && data.updateTreatingPhysician.__typename === 'Error') {
        handleErrors(data.updateTreatingPhysician.errors)
      } else {
        refetch()
        Notification.success(successMessage)
      }
    }

    onCloseEditor()
  }

  const onTreatingPhysicianChange = (option: Option | null): void => {
    if (option?.value === 'add_member') {
      // Open add team member modal
      onOpen()
    } else {
      setTreatingPhysicianId(option?.value)
      setTreatingPhysicianName(option?.label || '')
    }
  }

  const handleTPModalClose = (
    createdUser?: CreatePortalTeamMember_createPortalTeamMember_TeamMember
  ): void => {
    onClose()
    if (createdUser) {
      setTreatingPhysicianId(createdUser.id)
      setTreatingPhysicianName(createdUser.name || '')
    }
  }

  const treatingPhysicianOptions = useMemo(() => {
    const options: Option[] = []
    if (customerData?.customer.treatingPhysicians) {
      customerData?.customer.treatingPhysicians.map(t => {
        return options.push({
          value: t.id,
          label: t.name,
        })
      })
    }
    options.push({
      value: 'add_member',
      label: t('treatingPhysForm.add', { ns: ['orderShowScreen'] }),
      isLink: true,
    })
    return options
  }, [customerData?.customer.treatingPhysicians])

  const treatingPhysicianOptionVal = useMemo(() => {
    const value: Option | undefined = treatingPhysicianOptions.find(
      o => o.value === treatingPhysicianId
    )
    return value ?? null
  }, [
    treatingPhysicianId,
    treatingPhysicianName,
    customerData?.customer.treatingPhysicians,
  ])

  useEffect(() => {
    window.addEventListener('resize', () => {
      updateDimension({
        ...dimension,
        width: window.innerWidth,
      })
      const label =
        window.innerWidth < 720
          ? t('treatingPhysForm.treatingPhys', { ns: ['orderShowScreen'] })
          : t('treatingPhysForm.pleaseSelect', { ns: ['orderShowScreen'] })
      setTreatingPhyisicianLabel(label)
    })
  }, [window.innerWidth, window])

  return (
    <>
      <DetailText
        title={t('showShippingWidget.treatingPhys', {
          ns: ['orderShowScreen'],
        })}
        width={'100%'}
      >
        <Flex {...wrapperProps}>
          <>
            <CustomSelect
              placeholder={treatingPhyisicianLabel}
              value={treatingPhysicianOptionVal}
              onChange={option => onTreatingPhysicianChange(option as Option)}
              options={treatingPhysicianOptions}
              isSearchable={false}
              isClearable={true}
              data-cy="order-treating-physician-select"
              version={ThemeVersion.V2}
            />
            <Flex>
              <Button
                {...buttonStyles}
                maxHeight="60px"
                onClick={() =>
                  onUpdateTreatingPhysician(
                    treatingPhysicianId || '',
                    treatingPhysicianName || ''
                  )
                }
              >
                {t('treatingPhysForm.save', { ns: ['orderShowScreen'] })}
              </Button>
              <Button
                {...buttonStyles}
                backgroundColor={themeUtils.colors.red}
                maxHeight="60px"
                onClick={() => resetField()}
              >
                {t('treatingPhysForm.cancel', { ns: ['orderShowScreen'] })}
              </Button>
            </Flex>
          </>
        </Flex>
      </DetailText>
      {isROWCustomer(user) &&
        customerData?.customer?.treatingPhysicians?.find(
          o => o.id === treatingPhysicianId
        )?.portalUserId === null && (
          <InlineErrorMessage version={ThemeVersion.V2} icon={'info' as Icons}>
            {t('treatingPhysForm.message', { ns: ['orderShowScreen'] })}
          </InlineErrorMessage>
        )}
      <TeamMemberForm
        isOpen={isOpen}
        onClose={handleTPModalClose}
        source={TeamMemberSoucreEnum.ADD_TREATING_PHYSICIAN}
        headingTitle={t('treatingPhysForm.add', { ns: ['orderShowScreen'] })}
        submitBtnLabel={t('treatingPhysForm.add', { ns: ['orderShowScreen'] })}
      />
    </>
  )
}
