import { useMutation } from '@apollo/react-hooks'
import { Flex, FlexProps } from '@chakra-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UPDATE_TREATMENT_INTENT } from '../../../schema/mutations'
import {
  UpdateTreatmentIntent,
  UpdateTreatmentIntentVariables,
} from '../../../schema/mutations/__generated__/UpdateTreatmentIntent'
import { OrderShow_order } from '../../../schema/queries/__generated__/OrderShow'
import { themeUtils } from '../../../themeUtils'
import { handleErrors, Notification } from '../../../utils'
import { Button, DetailText, Option } from '../../Shared'
import { getTreatmentIntentOptions, PatientIntent } from '../NewOrderForm'

interface Props {
  order: OrderShow_order
  refetch: Function
  canEdit: boolean | undefined
  wrapperProps: FlexProps
  onCloseEditor: Function
}

const buttonStyles = {
  marginLeft: '1rem',
  height: 'auto',
  width: 'auto',
  minWidth: '6rem',
}

const { midnightBlue } = themeUtils.colors

export const PatientIntentForm: React.FC<Props> = ({
  order,
  refetch,
  canEdit = false,
  wrapperProps,
  onCloseEditor,
}) => {
  const { t } = useTranslation(['orderShowScreen'])
  const [patientIntentValue, setPatientIntentValue] = useState(
    order.treatmentIntent
  )

  const [updatePatientIntent] = useMutation<
    UpdateTreatmentIntent,
    UpdateTreatmentIntentVariables
  >(UPDATE_TREATMENT_INTENT)

  const resetField = () => {
    setPatientIntentValue(order.treatmentIntent ?? null)
    onCloseEditor()
  }

  const onUpdatePatientIntent = async (): Promise<void> => {
    const { data, errors } = await updatePatientIntent({
      variables: {
        input: {
          id: order?.id,
          treatmentIntent: patientIntentValue,
        },
      },
    })

    const successMessage = t('patientIntent.updated', {
      ns: ['orderShowScreen'],
    })
    refetch()

    if (errors) {
      errors.forEach(error => Notification.error(error.message))
    } else {
      if (data && data.updateTreatmentIntent.__typename === 'Error') {
        handleErrors(data.updateTreatmentIntent.errors)
      } else {
        refetch()
        Notification.success(successMessage)
      }
    }
    onCloseEditor()
  }

  const changePatientIntent = (option: Option | null): void => {
    setPatientIntentValue(option?.value ?? null)
  }

  const orderPatientIntentValue = getTreatmentIntentOptions().find(
    o => o.value == order.treatmentIntent
  )?.label

  return (
    <>
      <DetailText
        title={t('showShippingWidget.patientIntent', {
          ns: ['orderShowScreen'],
        })}
        width={'100%'}
      >
        <Flex {...wrapperProps}>
          <>
            <PatientIntent
              changePatientIntent={changePatientIntent}
              patientIntent={patientIntentValue}
              label=""
              hint=""
            />
            <Flex>
              <Button
                {...buttonStyles}
                maxHeight="60px"
                onClick={() => onUpdatePatientIntent()}
              >
                {t('patientIntent.save', { ns: ['orderShowScreen'] })}
              </Button>
              <Button
                {...buttonStyles}
                backgroundColor={themeUtils.colors.red}
                maxHeight="60px"
                onClick={() => resetField()}
              >
                {t('patientIntent.cancel', { ns: ['orderShowScreen'] })}
              </Button>
            </Flex>
          </>
        </Flex>
      </DetailText>
    </>
  )
}
