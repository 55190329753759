import React from 'react'
import { OrderShow_order } from '../../../schema/queries/__generated__/OrderShow'
import { OrderViewEditEnum } from '../../../types'
import { PatientIntentForm } from './PatientIntentForm'
import { ReferringPhysicianForm } from './ReferringPhysicianForm'
import { TreatingPhysicianForm } from './TreatingPhysicianForm'

interface Props {
  formType: OrderViewEditEnum
  order: OrderShow_order
  refetch: Function
  canEditOrders: boolean
  onCloseEditor: Function
}

export const OrderShowShippingWidgetForm: React.FC<Props> = ({
  formType,
  order,
  refetch,
  canEditOrders,
  onCloseEditor,
}) => {
  return formType === OrderViewEditEnum.TREATMENT_INTENT ? (
    <PatientIntentForm
      order={order}
      refetch={refetch}
      canEdit={canEditOrders}
      onCloseEditor={() => onCloseEditor(OrderViewEditEnum.TREATMENT_INTENT)}
      wrapperProps={{
        width: '100%',
        marginTop: { base: '1rem', sm: 0 },
        marginBottom: '1rem',
      }}
    />
  ) : formType === OrderViewEditEnum.TREATING_PHYSICIAN ? (
    <TreatingPhysicianForm
      order={order}
      refetch={refetch}
      canEdit={canEditOrders}
      onCloseEditor={() => {
        onCloseEditor(OrderViewEditEnum.TREATING_PHYSICIAN)
      }}
      wrapperProps={{
        width: '100%',
        marginTop: { base: '1rem', sm: 0 },
        marginBottom: '1rem',
      }}
    ></TreatingPhysicianForm>
  ) : formType === OrderViewEditEnum.REFERRING_PHYSICIAN ? (
    <ReferringPhysicianForm
      order={order}
      refetch={refetch}
      canEdit={canEditOrders}
      onCloseEditor={() => onCloseEditor(OrderViewEditEnum.REFERRING_PHYSICIAN)}
      wrapperProps={{
        width: '100%',
        marginTop: { base: '1rem', sm: 0 },
        marginBottom: '1rem',
      }}
    ></ReferringPhysicianForm>
  ) : null
}
