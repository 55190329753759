import { Box, Icon, Text, Tooltip } from '@chakra-ui/core'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { NPISearchResultTable } from '.'
import { TeamMemberProfessionEnum } from '../../../__generated__/globalTypes'
import {
  NPISearch,
  NPISearch_npiSearch_results,
} from '../../../schema/queries/__generated__/NPISearch'
import { themeUtils } from '../../../themeUtils'
import { LoadingIndicator } from '../../Shared'

interface Props {
  profession: TeamMemberProfessionEnum
  onNPISearchLinkClick: () => void
  isNPISearchMode: boolean
  npi: string
  data: NPISearch | undefined
  loading: boolean
  onSelectNPI: (result: NPISearch_npiSearch_results) => void
  linkLabel: string
}

export const NPIFieldWidget: React.FC<Props> = ({
  profession,
  onNPISearchLinkClick,
  isNPISearchMode,
  npi,
  data,
  loading,
  onSelectNPI,
  linkLabel,
}) => {
  const { t } = useTranslation(['npiWidget'])
  if (
    ![
      TeamMemberProfessionEnum.treating_physician,
      TeamMemberProfessionEnum.referring_physician,
    ].includes(profession)
  ) {
    return null
  }

  const haveNPI = (): ReactElement | null => {
    if (!npi || npi.length < 10) return null

    if (loading) {
      return <LoadingIndicator />
    }

    if (
      data?.npiSearch &&
      (!data.npiSearch.results || data.npiSearch?.results?.length === 0)
    ) {
      return (
        <Text color={themeUtils.colors.red} fontSize={'xl'}>
          {t('notFound', {
            ns: ['npiWidget'],
          })}
        </Text>
      )
    } else if (
      data?.npiSearch.results &&
      data.npiSearch.results?.length === 1
    ) {
      const { basic } = data.npiSearch.results[0]

      if (!basic) return null
      return (
        <Box display={'flex'}>
          <Text color={themeUtils.colors.blue} fontSize={'xl'}>
            {t('belongsTo', {
              ns: ['npiWidget'],
            })}{' '}
            <b>{`${basic.firstName ?? ''} ${basic.middleName ??
              ''} ${basic.lastName ?? ''}`}</b>
          </Text>
          <Tooltip
            aria-label="NPPES-NPI-Registry"
            label={t('info', {
              ns: ['npiWidget'],
            })}
            zIndex={1402}
            hasArrow
            placement="top"
            width={'18rem'}
            fontSize={'1.2rem'}
            hideDelay={200}
          >
            <Icon
              aria-label="NPPES NPI Registry"
              marginLeft={'0.5rem'}
              name="info"
              color={themeUtils.colors.blue}
            />
          </Tooltip>
        </Box>
      )
    }

    return null
  }

  const notHavingNPI = (): ReactElement | null => {
    if (npi) return null

    return (
      <Text color={themeUtils.colors.blue} fontSize={'xl'}>
        {linkLabel}{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
            fontWeight: 'bold',
          }}
          onClick={onNPISearchLinkClick}
        >
          {t('npiNumberField.searchHere', {
            ns: ['npiWidget'],
          })}
        </span>
      </Text>
    )
  }

  return (
    <>
      {/* NPI LINK AND MESSAGES */}
      {!isNPISearchMode && [haveNPI(), notHavingNPI()]}
      {/* NPI SEARCH SECTION */}
      {isNPISearchMode && (
        <NPISearchResultTable
          data={data?.npiSearch}
          loading={loading}
          onSelectNPI={onSelectNPI}
        />
      )}
    </>
  )
}
