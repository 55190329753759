import { useDisclosure } from '@chakra-ui/core'
import { Icons } from '@chakra-ui/core/dist/theme/icons'
import React, { useMemo } from 'react'
import { useQuery } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { useUser } from '../../../context/user-context'
import { CreatePortalTeamMember_createPortalTeamMember_TeamMember } from '../../../schema/mutations/__generated__/CreatePortalTeamMember'
import { GetCustomer } from '../../../schema/queries/__generated__/GetCustomer'
import { GET_CUSTOMER_QUERY } from '../../../schema/queries/CustomerQueries'
import { ThemeVersion } from '../../../types'
import { isROWCustomer } from '../../lib'
import { TeamMemberForm, TeamMemberSoucreEnum } from '../../MyAccount'
import { CustomSelect, InlineErrorMessage, Option } from '../../Shared'
import { OrderFormData } from '../OrderCreate/OrderFormDataTypes'

interface Props {
  formData: OrderFormData
  setFormData: (value: React.SetStateAction<OrderFormData>) => void
}

export const ReferringPhysician: React.FC<Props> = ({
  formData,
  setFormData,
}) => {
  const { t } = useTranslation(['orderCreate', 'orderShowScreen'])
  const user = useUser()
  const { data: customerData } = useQuery<GetCustomer>(GET_CUSTOMER_QUERY, {
    fetchPolicy: 'cache-first',
  })
  const {
    isOpen: isTPOpen,
    onOpen: onTPOpen,
    onClose: onTPClose,
  } = useDisclosure()

  const onReferringPhysicianChange = (option: Option | null): void => {
    if (option?.value === 'add_member') {
      // Open add team member modal
      onTPOpen()
    } else {
      setFormData((prevFormData: OrderFormData) => {
        return {
          ...prevFormData,
          referringPhysicianId: option?.value ?? undefined,
          referringPhysicianName: option?.label ?? undefined,
        }
      })
    }
  }

  const handleTPModalClose = (
    createdUser?: CreatePortalTeamMember_createPortalTeamMember_TeamMember
  ): void => {
    onTPClose()
    if (createdUser) {
      setFormData((prevFormData: OrderFormData) => {
        return {
          ...prevFormData,
          referringPhysicianId: createdUser.id,
          referringPhysicianName: createdUser.name,
        }
      })
    }
  }

  const referringPhysicianOptions = useMemo(() => {
    const options: Option[] = []
    if (customerData?.customer.referringPhysicians) {
      customerData?.customer.referringPhysicians.map(t => {
        return options.push({
          value: t.id,
          label: t.name,
        })
      })
    }
    options.push({
      value: 'add_member',
      label: t('orderCreateWidget.referringPhysicianField.addMenuLink', {
        ns: ['orderCreate'],
      }),
      isLink: true,
    })
    return options
  }, [customerData?.customer.referringPhysicians])

  const referringPhysicianOptionVal = useMemo(() => {
    const value: Option | undefined = referringPhysicianOptions.find(
      o => o.value === formData.referringPhysicianId
    )
    return value ?? null
  }, [
    customerData?.customer.referringPhysicians,
    formData.referringPhysicianId,
    formData.referringPhysicianName,
  ])

  return (
    <>
      <CustomSelect
        label={t('orderCreateWidget.referringPhysicianField.label', {
          ns: ['orderCreate'],
        })}
        placeholder={t(
          'orderCreateWidget.referringPhysicianField.placeholder',
          {
            ns: ['orderCreate'],
          }
        )}
        value={referringPhysicianOptionVal}
        onChange={option => onReferringPhysicianChange(option as Option)}
        options={referringPhysicianOptions}
        isSearchable={false}
        clearable={true}
        version={ThemeVersion.V2}
        classNamePrefix="referring-physician-select"
      />
      <TeamMemberForm
        isOpen={isTPOpen}
        onClose={handleTPModalClose}
        headingTitle={t(
          'orderCreateWidget.referringPhysicianField.formHeading',
          {
            ns: ['orderCreate'],
          }
        )}
        submitBtnLabel={t(
          'orderCreateWidget.referringPhysicianField.formSubmitBtn',
          {
            ns: ['orderCreate'],
          }
        )}
        source={TeamMemberSoucreEnum.ADD_REFERRING_PHYSICIAN}
      />
      {isROWCustomer(user) &&
        customerData?.customer?.referringPhysicians?.find(
          o => o.id === formData.referringPhysicianId
        )?.portalUserId === null && (
          <InlineErrorMessage version={ThemeVersion.V2} icon={'info' as Icons}>
            {t('referringPhysForm.message', { ns: ['orderShowScreen'] })}
          </InlineErrorMessage>
        )}
    </>
  )
}
